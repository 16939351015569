import { gql, useLazyQuery } from '@apollo/client';

import {
  FetchOnlineIdEmailQuery,
  FetchOnlineIdEmailQueryVariables,
} from '@typings/operations';

export const fetchOnlineIdEmailQuery = gql`
  query fetchOnlineIdEmail($individualId: String!) {
    getOnlineIdEmail(individualId: $individualId)
  }
`;

export const useFetchOnlineIdEmail = () =>
  useLazyQuery<FetchOnlineIdEmailQuery, FetchOnlineIdEmailQueryVariables>(
    fetchOnlineIdEmailQuery
  );
